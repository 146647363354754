<template>
  <div>
    <AppBar></AppBar>
    <v-container>
      <v-col>
        <v-row class="mt-10">
          <v-col>
            <div class="font-weight-black font-32">
              OUR WORK<span class="primary-color">.</span>
            </div>
          </v-col>
        </v-row>

        <v-card flat min-height="100vh">
          <v-tabs
            v-model="tab"
            background-color="transparent"
            color="#ffd12f"
            grow
          >
            <v-tab v-for="item in items" :key="item">
              {{ item }}
            </v-tab>
          </v-tabs>

          <v-tabs-items v-model="tab">
            <v-tab-item v-for="(section, x) in items" :key="x">
              <v-row class="mb-10">
                <v-col
                  md="4"
                  sm="6"
                  cols="12"
                  v-for="(img, i) in getTab(x)"
                  :key="i"
                >
                  <v-img :src="img"></v-img>
                </v-col>
              </v-row>
            </v-tab-item>
          </v-tabs-items>
        </v-card>
      </v-col>
    </v-container>
    <Banner></Banner>
    <Footer></Footer>
  </div>
</template>

<script>
// @ is an alias to /src
import AppBar from "@/components/AppBar.vue";
import Banner from "@/components/Banner.vue";
import Footer from "@/components/Footer.vue";

export default {
  name: "OurWorkView",
  data: () => ({
    gallery: [
      require("@/assets/images/bedroom1.jpg"),
      require("@/assets/images/gallery (1).png"),
      require("@/assets/images/gallery (1).jpg"),
      require("@/assets/images/bedroom2.png"),
      require("@/assets/images/gallery (2).png"),
      require("@/assets/images/gallery (3).png"),
      require("@/assets/images/bedroom3.png"),
      require("@/assets/images/gallery (4).png"),
      require("@/assets/images/gallery (5).png"),
      require("@/assets/images/bedroom4.png"),
      require("@/assets/images/gallery (6).png"),
    ],
    homes: [
      require("@/assets/images/homes1.png"),
      require("@/assets/images/homes2.png"),
      require("@/assets/images/homes3.jpg"),
      require("@/assets/images/homes4.png"),
    ],
    livingroom: [
      require("@/assets/images/livingroom-open-concept.png"),
      require("@/assets/images/livingroom-open-concept2.png"),
      require("@/assets/images/livingroom-open-concept3.png"),
      require("@/assets/images/livingroom-open-concept4.png"),
      require("@/assets/images/livingroom-open-concept5.png"),
      require("@/assets/images/livingroom-open-concept6.png"),
      require("@/assets/images/livingroom-open-concept7.png"),
      require("@/assets/images/livingroom-open-concept8.jpg"),
      require("@/assets/images/livingroom-open-concept9.jpg"),
    ],
    kitchen: [
      require("@/assets/images/kitchen1.png"),
      require("@/assets/images/kitchen2.png"),
      require("@/assets/images/kitchen3.png"),
      require("@/assets/images/kitchen4.png"),
      require("@/assets/images/kitchen5.jpg"),
      require("@/assets/images/kitchen6.png"),
      require("@/assets/images/kitchen7.png"),
      require("@/assets/images/kitchen8.jpg"),
    ],
    bathroom: [
      require("@/assets/images/bathroom1.png"),
      require("@/assets/images/bathroom2.png"),
      require("@/assets/images/bathroom3.png"),
      require("@/assets/images/bathroom4.png"),
      require("@/assets/images/bathroom5.png"),
      require("@/assets/images/bathroom6.png"),
      require("@/assets/images/bathroom7.jpg"),
    ],
    tab: null,
    items: ["Gallery", "Home", "Kitchen", "Living Room", "Bathroom"],
  }),
  components: {
    AppBar,
    Footer,
    Banner,
  },
  methods: {
    getTab(tab) {
      let arr = [];

      switch (tab) {
        case 0:
          arr = this.$data.gallery;
          break;

        case 1:
          arr = this.$data.homes;
          break;

        case 2:
          arr = this.$data.kitchen;
          break;

        case 3:
          arr = this.$data.livingroom;
          break;

        case 4:
          arr = this.$data.bathroom;
          break;

        default:
          break;
      }

      return arr;
    },
  },
  mounted() {
    switch (this.$route.hash) {
      case "#home":
        this.$data.tab = 1;
        break;
      case "#kitchen":
        this.$data.tab = 2;
        break;
      case "#livingroom":
        this.$data.tab = 3;
        break;
      case "#bathroom":
        this.$data.tab = 4;
        break;
      default:
        break;
    }
  },
};
</script>

<style scoped>
.custom-card {
  border: 3px solid #ffd12f !important;
}
</style>
