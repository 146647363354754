<template>
  <v-row class="mb-10">
    <v-col>
      <v-img
        src="@/assets/banner-image.png"
        class="d-flex align-center text-center white--text"
        :height="$vuetify.breakpoint.mdAndUp ? '300px' : '200px'"
      >
        <v-row>
          <v-col>
            <div
              class="font-weight-black mb-5"
              :class="$vuetify.breakpoint.mdAndUp ? 'font-32' : 'font-20'"
            >
              SCHEDULE YOUR FREE IN-HOME CONSULTATION
            </div>
            <v-btn
              outlined
              large
              color="white"
              class="font-weight-bold"
              to="/contact-us"
            >
              CONTACT US
            </v-btn>
          </v-col>
        </v-row>
      </v-img>
    </v-col>
  </v-row>
</template>

<script>
export default {
  name: "BannerSection",
};
</script>
