<template>
  <v-layout :class="$route.name !== 'home' ? 'dark-bg' : ''">
    <v-container>
      <v-row align="center" class="py-5 font-20">
        <v-col
          :class="$vuetify.breakpoint.smAndDown ? 'text-right' : 'text-center'"
        >
          <v-row v-show="$vuetify.breakpoint.mdAndUp" no-gutters>
            <v-col>
              <router-link to="/" class="font-weight-bold text-uppercase">
                Home
              </router-link>
            </v-col>
            <v-col>
              <router-link
                to="/our-work"
                class="font-weight-bold text-uppercase"
              >
                Our Work
              </router-link>
            </v-col>
            <v-col>
              <router-link
                to="/about-us"
                class="font-weight-bold text-uppercase"
              >
                About Us
              </router-link>
            </v-col>
            <v-col>
              <router-link
                to="/contact-us"
                class="font-weight-bold text-uppercase"
              >
                Contact Us
              </router-link>
            </v-col>
          </v-row>
          <v-menu transition="slide-y-transition">
            <template v-slot:activator="{ on, attrs }">
              <v-btn
                color="#ffffff"
                class="ma-2"
                v-bind="attrs"
                v-on="on"
                v-show="$vuetify.breakpoint.smAndDown"
                icon
                x-large
              >
                <v-icon>mdi-menu</v-icon>
              </v-btn>
            </template>
            <v-list color="#202020" width="100vw">
              <v-list-item link to="/">
                <v-list-item-title>Home</v-list-item-title>
              </v-list-item>
              <v-list-item link to="/our-work">
                <v-list-item-title>Our Works</v-list-item-title>
              </v-list-item>
              <v-list-item link to="/about-us">
                <v-list-item-title>About Us</v-list-item-title>
              </v-list-item>
              <v-list-item link to="/contact-us">
                <v-list-item-title>Contact Us</v-list-item-title>
              </v-list-item>
            </v-list>
          </v-menu>
        </v-col>
      </v-row>
    </v-container>
  </v-layout>
</template>

<script>
export default {
  name: "AppBar",
};
</script>
