<template>
  <div class="about">
    <AppBar></AppBar>
    <v-container>
      <v-row>
        <v-col>
          <v-row class="my-10">
            <v-col>
              <div class="font-weight-black font-32">
                ABOUT US<span class="primary-color">.</span>
              </div>
            </v-col>
          </v-row>

          <v-row>
            <v-col>
              <div class="font-weight-bold font-30 mb-5">THE COMPANY</div>
              <div class="font-20">
                4EVER HOMES LLC is a well-established firm built on: Dedication,
                Communication, Integrity & Service while bringing solutions to
                homeowners in the DC urban area, Maryland and Nova. With 10
                years of valuable experience, our skilled team of designers and
                craftsmen work to provide strategic marketing and transaction
                management services focused on residential remodelling projects
                including additions, kitchen, bathrooms, art galleries,
                mixed-use spaces and so much more. Along with great connections
                to realtors and brokers, solid follow-up and communication we
                embody the ability to cater and adapt to each individual
                client's real estate needs.
              </div>
            </v-col>
          </v-row>

          <v-row class="mb-10">
            <v-col>
              <div class="font-weight-bold font-30 mb-5 mt-10">THE TEAM</div>
              <v-row class="text-center mb-5">
                <v-col>
                  <v-img
                    src="@/assets/team-placeholder.png"
                    max-height="200px"
                    contain
                  >
                  </v-img>
                  <div class="font-weight-bold font-20 mt-5">
                    HAROLD LA TORRE
                  </div>
                  <div>Owner</div>
                </v-col>
              </v-row>
              <v-row class="text-center">
                <v-col>
                  <v-img
                    src="@/assets/team-placeholder.png"
                    max-height="200px"
                    contain
                  >
                  </v-img>
                  <div class="font-weight-bold font-20 mt-5">JOSE PEREZ</div>
                  <div>Project Manager</div>
                </v-col>
                <v-col>
                  <v-img
                    src="@/assets/team-placeholder.png"
                    max-height="200px"
                    contain
                  >
                  </v-img>
                  <div class="font-weight-bold font-20 mt-5">
                    JOSE FELICIANO
                  </div>
                  <div>Operational Manager</div>
                </v-col>
              </v-row>
            </v-col>
          </v-row>
        </v-col>
      </v-row>
    </v-container>
    <Banner></Banner>
    <Footer></Footer>
  </div>
</template>

<script>
// @ is an alias to /src
import AppBar from "@/components/AppBar.vue";
import Banner from "@/components/Banner.vue";
import Footer from "@/components/Footer.vue";

export default {
  name: "HomeView",
  components: {
    AppBar,
    Footer,
    Banner,
  },
};
</script>

<style></style>
