<template>
  <div class="home">
    <v-row no-gutters>
      <v-col>
        <v-layout fill-height>
          <v-carousel
            id="carousel-fade"
            :show-arrows="false"
            hide-delimiters
            height="100vh"
            width="100vw"
            cycle
          >
            <v-carousel-item
              v-for="(image, i) in images"
              :key="i"
              :interval="5000"
              transition="fade-transition"
              reverse-transition="fade-transition"
              eager
            >
              <v-img
                :src="image"
                height="100vh"
                min-width="100vw"
                eager
              ></v-img>
            </v-carousel-item>
            <transition name="fade-y" mode="out-in">
              <div class="front-div" v-if="show">
                <div>
                  <AppBar></AppBar>
                  <v-row align="end" style="height: 80vh">
                    <v-col class="text-center white--text">
                      <div
                        class="font-weight-black space-10"
                        :class="
                          $vuetify.breakpoint.mdAndUp ? 'font-64' : 'font-32'
                        "
                      >
                        4EVERHOMES LLC.
                      </div>
                      <div
                        class="font-weight-light space-35"
                        :class="
                          $vuetify.breakpoint.mdAndUp ? 'font-40' : 'font-20'
                        "
                      >
                        BUY | SELL | REMODEL
                      </div>
                      <v-btn
                        outlined
                        :x-large="$vuetify.breakpoint.mdAndUp"
                        color="#ffffff"
                        class="mt-5 font-weight-bold"
                        to="/our-work"
                      >
                        VIEW OUR WORKS
                      </v-btn>
                    </v-col>
                  </v-row>
                </div>
              </div>
            </transition>
          </v-carousel>
        </v-layout>
      </v-col>
    </v-row>
    <v-container>
      <v-row class="my-10">
        <v-col class="font-weight-medium font-20">
          <div class="mb-5">
            4EVERHOMES LLC is a full-service home solutions provider that serves
            the Washington, DC metropolitan area, including Maryland and
            Northern Virginia. We BUY, SELL, and REMODEL homes. Complete home
            renovations, home additions, kitchen and bathroom design are among
            the services we offer. Our distinctive design/build method, which
            enables us to offer architecture, design, and construction services,
            sets us apart from other home builders in Washington, DC, Maryland
            and Northern Virginia.
          </div>
          <v-row class="my-10">
            <v-col sm="4" cols="12">
              <v-card outlined class="custom-card" height="100%">
                <div class="d-flex justify-center">
                  <v-img
                    src="@/assets/home-icon-1.png"
                    max-width="125px"
                    contain
                  ></v-img>
                </div>
                <v-card-title
                  class="font-weight-bold"
                  :class="$vuetify.breakpoint.mdAndUp ? 'font-32' : 'font-20'"
                >
                  WE BUY
                </v-card-title>
                <v-card-text class="">
                  Need a quick cash offer on your house? We are the right place
                  to go.
                </v-card-text>
              </v-card>
            </v-col>
            <v-col sm="4" cols="12">
              <v-card outlined class="custom-card" height="100%">
                <div class="d-flex justify-center">
                  <v-img
                    src="@/assets/home-icon-2.png"
                    max-width="125px"
                    contain
                  ></v-img>
                </div>
                <v-card-title
                  class="font-weight-bold"
                  :class="$vuetify.breakpoint.mdAndUp ? 'font-32' : 'font-20'"
                >
                  WE SELL
                </v-card-title>
                <v-card-text>
                  Need to sell your house on MLS? Our savvy team of Realtors
                  will make your experience smooth and easier.
                </v-card-text>
              </v-card>
            </v-col>
            <v-col sm="4" cols="12">
              <v-card outlined class="custom-card" height="100%">
                <div class="d-flex justify-center">
                  <v-img
                    src="@/assets/home-icon-3.png"
                    max-width="125px"
                    contain
                  ></v-img>
                </div>
                <v-card-title
                  class="font-weight-bold"
                  :class="$vuetify.breakpoint.mdAndUp ? 'font-32' : 'font-20'"
                >
                  WE REMODEL
                </v-card-title>
                <v-card-text>
                  Like houses on TV? Bring your ideas and our team of designers
                  and builders will go above and beyond to meet your
                  requirements.
                </v-card-text>
              </v-card>
            </v-col>
          </v-row>
          <div
            class="text-center font-weight-bold text-uppercase"
            :class="$vuetify.breakpoint.mdAndUp ? 'font-32' : 'font-20'"
          >
            <i>
              We provide any kind of solution for homeowners IN-HOUSE and under
              one umbrella
            </i>
          </div>
        </v-col>
      </v-row>
      <v-row class="mb-10">
        <v-col lg="3" md="6" cols="12">
          <router-link to="/our-work/#home">
            <v-img
              src="@/assets/images/maidenhair-front.png"
              class="d-flex align-center text-center"
            >
              <div class="font-weight-bold font-24 white--text">HOME</div>
            </v-img>
          </router-link>
        </v-col>
        <v-col lg="3" md="6" cols="12">
          <router-link to="/our-work/#kitchen">
            <v-img
              src="@/assets/images/kitchen.png"
              class="d-flex align-center text-center"
            >
              <div class="font-weight-bold font-24 white--text">KITCHEN</div>
            </v-img>
          </router-link>
        </v-col>
        <v-col lg="3" md="6" cols="12">
          <router-link to="/our-work/#livingroom">
            <v-img
              src="@/assets/images/livingroom.png"
              class="d-flex align-center text-center"
            >
              <div class="font-weight-bold font-24 white--text">
                LIVING ROOM
              </div>
            </v-img>
          </router-link>
        </v-col>
        <v-col lg="3" md="6" cols="12">
          <router-link to="/our-work/#bathroom">
            <v-img
              src="@/assets/images/bathroom.png"
              class="d-flex align-center text-center"
            >
              <div class="font-weight-bold font-24 white--text">BATHROOM</div>
            </v-img>
          </router-link>
        </v-col>
      </v-row>
    </v-container>
    <Banner></Banner>
    <Footer></Footer>
  </div>
</template>

<script>
// @ is an alias to /src
import AppBar from "@/components/AppBar.vue";
import Banner from "@/components/Banner.vue";
import Footer from "@/components/Footer.vue";

export default {
  name: "HomeView",
  data: () => ({
    show: false,
    activeImg: 0,
    images: [
      require("@/assets/images/home-living.png"),
      require("@/assets/images/home-dining.png"),
      require("@/assets/images/home-bedroom.png"),
      require("@/assets/images/home-bathroom.png"),
      require("@/assets/images/home-kitchen.png"),
      require("@/assets/images/kitchen8.jpg"),
    ],
  }),
  methods: {},
  components: {
    AppBar,
    Footer,
    Banner,
  },
  mounted() {
    setTimeout(() => {
      this.show = !this.show;
    }, 4000);
  },
};
</script>

<style>
.custom-card {
  border: 3px solid #ffd12f !important;
  padding: 0.5em;
}

.front-div {
  position: absolute;
  z-index: 1;
  width: 100vw;
  text-shadow: 0px 0px 2px #000;
}
</style>

<style lang="sass">
.fade-transition
  &-leave-active, &-enter-active
    position: absolute
    top: 0
    width: 100%

  &-enter-active, &-leave, &-leave-to
    transition: opacity .5s ease-in

  &-enter, &-leave-to
    opacity: 0
</style>
