import Vue from "vue";
import VueRouter from "vue-router";
import HomeView from "@/views/HomeView.vue";
import AboutView from "@/views/AboutView.vue";
import ContactUsView from "@/views/ContactUsView.vue";
import PropertiesView from "@/views/OurWorkView.vue";

Vue.use(VueRouter);

const routes = [
  {
    path: "/",
    name: "home",
    component: HomeView,
  },
  {
    path: "/about-us",
    name: "about",
    component: AboutView,
  },
  {
    path: "/contact-us",
    name: "contactus",
    component: ContactUsView,
  },
  {
    path: "/our-work",
    name: "ourwork",
    component: PropertiesView,
  },
];

const router = new VueRouter({
  mode: "history",
  base: process.env.BASE_URL,
  routes,
  // eslint-disable-next-line no-unused-vars
  scrollBehavior(to, from, savedPosition) {
    return { x: 0, y: 0 };
  },
});

export default router;
