<template>
  <v-layout class="dark-bg">
    <v-container>
      <v-row class="my-5">
        <v-col>
          <v-row align="center">
            <v-col
              :class="
                $vuetify.breakpoint.mdAndDown ? 'd-flex justify-center' : ''
              "
            >
              <v-img
                src="@/assets/Horizontal_Logo.png"
                max-height="100px"
                max-width="300px"
                contain
              ></v-img>
            </v-col>
            <v-col lg="4" cols="12">
              <v-row
                :class="
                  $vuetify.breakpoint.mdAndUp ? 'text-right' : 'text-center'
                "
              >
                <v-col md="4" cols="12">
                  <router-link to="/our-work">Our Works</router-link>
                </v-col>
                <v-col md="4" cols="12">
                  <router-link to="/about-us">About Us</router-link>
                </v-col>
                <v-col md="4" cols="12">
                  <router-link to="/contact-us">Contact Us</router-link>
                </v-col>
              </v-row>
            </v-col>
          </v-row>
          <v-divider color="#ffffff" class="my-5"></v-divider>
          <v-row style="color: #848383">
            <v-col
              :class="$vuetify.breakpoint.lgAndUp ? 'text-left' : 'text-center'"
            >
              <div>Privacy Policy</div>
            </v-col>
            <v-col lg="4" cols="12">
              <v-row
                :class="
                  $vuetify.breakpoint.mdAndUp ? 'text-right' : 'text-center'
                "
              >
                <v-col>
                  <div>© 2022 4EVERHOMES LLC. All Rights Reserved.</div>
                </v-col>
              </v-row>
            </v-col>
          </v-row>
        </v-col>
      </v-row>
    </v-container>
  </v-layout>
</template>

<script>
export default {
  name: "FooterSection",
};
</script>

<style scoped></style>
