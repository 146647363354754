<template>
  <div class="contact-us">
    <AppBar></AppBar>
    <v-container>
      <v-row class="mb-10">
        <v-col>
          <v-row class="my-10">
            <v-col>
              <div class="font-weight-black font-32">
                CONTACT US<span class="primary-color">.</span>
              </div>
            </v-col>
          </v-row>

          <v-row>
            <v-col class="d-flex justify-center">
              <v-card
                outlined
                class="custom-card"
                :width="$vuetify.breakpoint.mdAndUp ? '75%' : '100%'"
                :class="$vuetify.breakpoint.mdAndUp ? 'pa-15' : 'pa-5'"
              >
                <v-row align="center" no-gutters>
                  <v-col
                    md="6"
                    cols="12"
                    :class="$vuetify.breakpoint.smAndDown ? 'mb-5' : ''"
                  >
                    <div class="font-weight-bold font-32 mb-5">
                      GET IN TOUCH WITH US
                    </div>
                    <v-row
                      align="center"
                      :no-gutters="$vuetify.breakpoint.smAndDown"
                    >
                      <v-col md="2">
                        <v-img
                          src="@/assets/phone-icon.png"
                          max-height="50px"
                          max-width="50px"
                          contain
                        ></v-img>
                      </v-col>
                      <v-col>
                        <div
                          :class="$vuetify.breakpoint.mdAndUp ? 'font-20' : ''"
                        >
                          +1 (571) 243-7804
                        </div>
                      </v-col>
                    </v-row>
                    <v-row
                      align="center"
                      :no-gutters="$vuetify.breakpoint.smAndDown"
                      :class="$vuetify.breakpoint.smAndDown ? 'my-5' : ''"
                    >
                      <v-col md="2">
                        <v-img
                          src="@/assets/email-icon.png"
                          max-height="50px"
                          max-width="50px"
                          contain
                        ></v-img>
                      </v-col>
                      <v-col>
                        <div
                          :class="$vuetify.breakpoint.mdAndUp ? 'font-20' : ''"
                        >
                          info@4everhomesllc.com
                        </div>
                      </v-col>
                    </v-row>
                    <v-row
                      align="center"
                      :no-gutters="$vuetify.breakpoint.smAndDown"
                    >
                      <v-col md="2">
                        <v-img
                          src="@/assets/insta-icon.png"
                          max-height="50px"
                          max-width="50px"
                          contain
                        ></v-img>
                      </v-col>
                      <v-col>
                        <div
                          :class="$vuetify.breakpoint.mdAndUp ? 'font-20' : ''"
                        >
                          @4everhomes.llc
                        </div>
                      </v-col>
                    </v-row>
                    <v-row
                      align="center"
                      :no-gutters="$vuetify.breakpoint.smAndDown"
                    >
                      <v-col md="2">
                        <v-img
                          src="@/assets/fb-icon.png"
                          max-height="50px"
                          max-width="50px"
                          contain
                        ></v-img>
                      </v-col>
                      <v-col>
                        <div
                          :class="$vuetify.breakpoint.mdAndUp ? 'font-20' : ''"
                          onclick="window.open('https://www.facebook.com/profile.php?id=100089060164663&mibextid=ZbWKwL')"
                        >
                          4everhomes LLC
                        </div>
                      </v-col>
                    </v-row>
                    <v-row
                      align="center"
                      :no-gutters="$vuetify.breakpoint.smAndDown"
                    >
                      <v-col md="2">
                        <v-img
                          src="@/assets/pin-icon.png"
                          max-height="50px"
                          max-width="50px"
                          contain
                        ></v-img>
                      </v-col>
                      <v-col>
                        <div
                          :class="$vuetify.breakpoint.mdAndUp ? 'font-20' : ''"
                        >
                          7347 Comfort Inn Dr, Warrenton, VA 20187
                        </div>
                      </v-col>
                    </v-row>
                  </v-col>
                  <v-col>
                    <form ref="form">
                      <v-container style="color: #202020">
                        <v-row class="mb-5 font-weight-bold">
                          <v-col cols="12">
                            <v-text-field
                              v-model="name"
                              name="name"
                              :rules="nameRules"
                              label="Name"
                              required
                              color="#ffd12f"
                            >
                            </v-text-field>
                            <v-text-field
                              v-model="email"
                              name="email"
                              :rules="emailRules"
                              label="E-mail"
                              required
                              color="#ffd12f"
                            >
                            </v-text-field>
                            <v-text-field
                              v-model="phone"
                              name="phone"
                              :rules="phoneRules"
                              label="Phone"
                              required
                              color="#ffd12f"
                            >
                            </v-text-field>
                            <v-text-field
                              v-model="location"
                              name="location"
                              :rules="locationRules"
                              label="Location"
                              required
                              color="#ffd12f"
                            >
                            </v-text-field>
                            <v-textarea
                              v-model="description"
                              name="description"
                              :rules="descriptionRules"
                              label="Description of the Project"
                              color="#ffd12f"
                            >
                            </v-textarea>
                          </v-col>
                        </v-row>
                        <v-alert
                          v-show="showErrorMessage"
                          type="error"
                          dismissible
                        >
                          Please input valid information.
                        </v-alert>
                        <v-btn
                          color="#ffd12f"
                          large
                          depressed
                          class="font-weight-bold"
                          @click="sendEmail"
                        >
                          SEND MESSAGE
                        </v-btn>
                      </v-container>
                    </form>
                  </v-col>
                </v-row>
              </v-card>
            </v-col>
          </v-row>

          <v-dialog v-model="showPopup" max-width="35%">
            <v-alert
              border="top"
              color="#ffd12f"
              class="text-center mb-0"
              dismissible
            >
              <div class="font-30">Thank you for contacting 4Ever Homes</div>

              <div class="font-20">
                We appreciate you contacting us. We will be in touch with you
                soon! Have a great day!
              </div>
            </v-alert>
          </v-dialog>
        </v-col>
      </v-row>
    </v-container>
    <Footer></Footer>
  </div>
</template>

<script>
// @ is an alias to /src
import AppBar from "@/components/AppBar.vue";
import Footer from "@/components/Footer.vue";
import emailjs from "@emailjs/browser";

export default {
  name: "HomeView",
  data: () => ({
    valid: false,
    name: "",
    nameRules: [(v) => !!v || "Name is required"],
    email: "",
    emailRules: [
      (v) => !!v || "E-mail is required",
      (v) => /.+@.+\..+/.test(v) || "E-mail must be valid",
    ],
    phone: "",
    phoneRules: [
      (v) =>
        /^(\+\d{1,2}\s)?\(?\d{3}\)?[\s.-]\d{3}[\s.-]\d{4}$/.test(v) ||
        "Phone number must be valid. Format: XXX-XXX-XXXX",
    ],
    location: "",
    locationRules: [(v) => !!v || "Location is required"],
    description: "",
    descriptionRules: [(v) => !!v || "Description is required"],
    showPopup: false,
    showErrorMessage: false,
  }),
  methods: {
    sendEmail() {
      this.$refs.form.validate
        ? emailjs
            .sendForm(
              "service_zeb9hj7",
              "template_ma1v8h9",
              this.$refs.form,
              "IybjoSMMFlhyhuEeQ"
            )
            .then(
              (result) => {
                console.log("SUCCESS!", result.text);
                this.showPopup = !this.showPopup;
              },
              (error) => {
                console.log("FAILED...", error.text);
              }
            )
        : (this.showErrorMessage = !this.showErrorMessage);

      this.$refs.form.reset();
    },
  },
  components: {
    AppBar,
    Footer,
  },
};
</script>

<style scoped>
.custom-card {
  border: 3px solid #ffd12f !important;
}
</style>
